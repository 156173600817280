import { Card } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

import SettingWebhook from '@feature/studio/setting/SettingWebhook';

import ButtonElement from '@components/atoms/ButtonElement';
import InteractiveConsole from '@components/console/InteractiveConsole';

import { snippetAutomateSelector, snippetTypeAtom } from '@store/Snippet';

import { snippetTypes } from '@constants/Snippet';

function Automate() {
  const snippetStateValue = useRecoilValue(snippetAutomateSelector);
  const [currentType, setSnippetType] = useRecoilState(snippetTypeAtom);

  return (
    <div className="max-w-screen-lg mx-auto p-10">
      <p className="text-lg text-center font-bold mb-8">
        Send an API request to create a video. <br />
        Poll the API or use a webhook to get notified when the video is ready.
      </p>

      <Card>
        <Card.Body>
          <div className="flex flex-col gap-5">
            <div>
              <p className="font-bold mb-3">1. Select the payload format</p>
              <div className="flex gap-2">
                {['id', 'blob'].map((slug) => (
                  <ButtonElement
                    key={slug}
                    variant={slug === currentType ? 'primary' : 'light'}
                    handleEvent={() => setSnippetType(slug)}
                  >
                    {snippetTypes[slug]}
                  </ButtonElement>
                ))}
              </div>
            </div>

            <div>
              <p className="font-bold mb-3">2. Set up your webhook callback (optional)</p>
              <SettingWebhook />
            </div>

            <div>
              <p className="font-bold mb-3">3. Send the request</p>
              <InteractiveConsole
                product="edit-api"
                compact
                initialPayload={snippetStateValue}
                initialConfig="editRenderATemplate"
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Automate;
