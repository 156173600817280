import { atom } from 'recoil';

export const renderSubmittedAtom = atom({
  key: 'renderSubmittedAtom',
  default: false,
});

export const rendersAtom = atom({
  key: 'rendersAtom',
  default: [],
});

export const rendersPanelAtom = atom({
  key: 'rendersPanelAtom',
  default: false,
});

export const renderFastPreviewAtom = atom({
  key: 'renderFastPreviewAtom',
  default: false,
});

export const renderPreviewStartAtom = atom({
  key: 'renderPreviewStartAtom',
  default: '',
});

export const renderPreviewLengthAtom = atom({
  key: 'renderPreviewLengthAtom',
  default: '',
});
