import { atom, atomFamily, selector, useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import { processKeyframesIncoming } from '@api/transform/utils/keyframes';

import { hydrateClipKeyframesCallback } from '@store/Keyframes';
import { mergeFamily, mergeIdsAtom } from '@store/Merge';
import { hydrateOutputCallback, resetOutputCallback } from '@store/Output';
import { templateJsonEditAtom, templateReadyAtom } from '@store/Template';
import { templateMediaPersist } from '@store/effects/MediaEffects';

import { getFont } from '@utils/fonts';
import { getReplacedData } from '@utils/merge';
import { reorganiseTracks } from '@utils/tracks';

const DEFAULT_BACKGROUND_COLOR = '#FFFFFF';
const DEFAULT_CACHE_STATE = true;

export const templateMediaState = atomFamily({
  key: 'edit/template/media',
  default: { loading: false, status: 'init', data: null },
  effects_UNSTABLE: templateMediaPersist,
});

export const workspaceTabAtom = atom({
  key: 'edit/workspace/tab',
  default: 'design',
});

export const settingsTabAtom = atom({
  key: 'edit/settings/tab',
  default: 'properties',
});

export const isKeyframesTabSelector = selector({
  key: 'edit/isKeyframesTabSelector',
  get: ({ get }) => {
    const selectedSettingsTab = get(settingsTabAtom);
    return selectedSettingsTab === 'keyframes';
  },
});

export const hasMergeFieldsSelector = selector({
  key: 'edit/merge/hasMergeFieldsSelector',
  get: ({ get }) => {
    const mergeIds = get(mergeIdsAtom);
    const mergeFields = mergeIds.map((mergeId) => get(mergeFamily(mergeId)));
    const mergeFieldsWithKey = mergeFields.filter((mergeField) => mergeField.find);
    return mergeFieldsWithKey.length > 0;
  },
});

export const callbackAtom = atom({
  key: 'edit/callback',
  default: {},
});

export const diskAtom = atom({
  key: 'edit/disk',
  default: null,
});

export const soundtrackAtom = atom({
  key: 'edit/template/soundtrack',
  default: null,
});

export const backgroundAtom = atom({
  key: 'edit/template/background',
  default: DEFAULT_BACKGROUND_COLOR,
});

export const fontIdsAtom = atom({
  key: 'edit/template/font/ids',
  default: [],
});

export const fontsAtomFamily = atomFamily({
  key: 'edit/template/fonts',
  default: {},
});

export const cacheAtom = atom({
  key: 'edit/template/cache',
  default: DEFAULT_CACHE_STATE,
});

export const trackIdsAtom = atom({
  key: 'edit/timeline/track/ids',
  default: [],
});

export const clipIdsAtom = atom({
  key: 'edit/timeline/clip/ids',
  default: [],
});

export const assetIdsAtom = atom({
  key: 'edit/timeline/asset/ids',
  default: [],
});

export const clipsAtomFamily = atomFamily({
  key: 'edit/timeline/track/clip',
  default: {},
});

export const clipErrorsAtomFamily = atomFamily({
  key: 'edit/studio/clip/errors',
  default: {},
});

export const overridesAtomFamily = atomFamily({
  key: 'edit/studio/clip/overrides',
  default: {},
});

export const clipsTracksAtomFamily = atomFamily({
  key: 'edit/timeline/clip/track',
  default: {},
});

export const isValidJsonAtom = atom({
  key: 'isValidJson',
  default: true,
});

const addFontStateCallback = (callbackArgs) => {
  const { set } = callbackArgs;
  return async ({ src }) => {
    const id = uuid();
    set(fontIdsAtom, (currentState) => {
      return [...currentState, id];
    });
    const [updatedFont, error] = await getFont({ src });
    if (error) {
      return [undefined, error];
    }
    set(fontsAtomFamily(id), updatedFont);
    return [updatedFont, undefined];
  };
};

const deleteFontStateCallback = (callbackArgs) => {
  const { set, reset } = callbackArgs;
  return (id) => {
    set(fontIdsAtom, (currentState) => currentState.filter((fontId) => fontId !== id));
    reset(fontsAtomFamily(id));
  };
};

// Helper functions
const resetCurrentState = ({ snapshot, reset, cb }) => {
  const resetAtomFamily = (atomFamily, idsAtom) => {
    const ids = snapshot.getLoadable(idsAtom).contents;
    ids.forEach((id) => reset(atomFamily(id)));
  };

  resetAtomFamily(clipsAtomFamily, clipIdsAtom);
  resetAtomFamily(overridesAtomFamily, clipIdsAtom);
  resetAtomFamily(fontsAtomFamily, fontIdsAtom);
  resetAtomFamily(mergeFamily, mergeIdsAtom);

  [trackIdsAtom, clipIdsAtom, assetIdsAtom, fontIdsAtom, mergeIdsAtom, callbackAtom, soundtrackAtom].forEach(reset);
  reset(overridesAtomFamily('callback'));
  reset(overridesAtomFamily('soundtrack'));

  if (cb) cb();
};

const setNewState = ({ set, disk, timeline, replacements, callback, soundtrack }) => {
  set(diskAtom, disk || null);
  set(backgroundAtom, timeline?.background || DEFAULT_BACKGROUND_COLOR);
  set(cacheAtom, () => (timeline?.cache === false ? timeline.cache : DEFAULT_CACHE_STATE));

  const setReplacedData = (atom, overridesAtom, data) => {
    const { merged, overrides } = getReplacedData(data, replacements);
    set(overridesAtom, overrides);
    set(atom, merged);
  };

  setReplacedData(callbackAtom, overridesAtomFamily('callback'), { src: callback });
  setReplacedData(soundtrackAtom, overridesAtomFamily('soundtrack'), soundtrack);
};

const processMergeFields = ({ set, merge }) => {
  (merge || []).forEach(({ find, replace }, index) => {
    const mergeId = uuid();
    set(mergeIdsAtom, (currentState) => (!currentState[index] ? currentState.concat([mergeId]) : currentState));
    const type = typeof replace === 'undefined' ? 'string' : typeof replace;
    set(mergeFamily(mergeId), { id: mergeId, find, replace, meta: { type } });
  });
};

const processTracksAndClips = ({ set, organisedTracks, replacements, cb }) => {
  organisedTracks?.forEach((track, trackIndex) => {
    const trackId = uuid();
    set(trackIdsAtom, (currentState) => (!currentState[trackIndex] ? currentState.concat([trackId]) : currentState));

    track.forEach((clip) => {
      const clipId = uuid();
      const rawClip = { id: clipId, ...clip };

      set(clipIdsAtom, (currentState) => currentState.concat([clipId]));

      cb(clipId, processKeyframesIncoming(clip));

      const { merged: mergedClip, overrides: clipOverrides } = getReplacedData(rawClip, replacements);
      set(clipsTracksAtomFamily(clipId), { clipId, trackId, trackIndex });
      set(clipsAtomFamily(clipId), mergedClip);
      set(overridesAtomFamily(clipId), clipOverrides);
    });
  });
};

const processFonts = ({ set, fonts }) => {
  fonts?.forEach(async (font, fontIndex) => {
    const fontId = uuid();
    set(fontIdsAtom, (currentState) => (!currentState[fontIndex] ? currentState.concat([fontId]) : currentState));
    const [newFont, error] = await getFont(font);
    if (error) {
      console.error(error);
    } else {
      set(fontsAtomFamily(fontId), { id: fontId, ...font, ...newFont });
    }
  });
};

// Main callback
const hydrateStoreFromTemplateCallback = (callbackArgs) => {
  const { set, reset, snapshot } = callbackArgs;
  const hydrateOutput = hydrateOutputCallback(callbackArgs);
  const hydrateClipKeyframes = hydrateClipKeyframesCallback(callbackArgs);
  const resetOutput = resetOutputCallback(callbackArgs);

  return async (json) => {
    const { output, merge, callback, disk, timeline } = json || {};
    const { soundtrack, tracks, fonts } = timeline || {};

    const replacements = (merge || []).reduce((acc, { find, replace }) => ({ ...acc, [find]: replace }), {});
    const organisedTracks = reorganiseTracks(tracks);

    resetCurrentState({ snapshot, reset, cb: resetOutput });
    setNewState({ set, disk, timeline, replacements, callback, soundtrack });
    processMergeFields({ set, merge });
    processTracksAndClips({ set, organisedTracks, replacements, cb: hydrateClipKeyframes });
    processFonts({ set, fonts });

    hydrateOutput(output);
    set(templateReadyAtom, true);
    set(templateJsonEditAtom, true);
  };
};

export const useAddFontState = () => useRecoilCallback(addFontStateCallback);
export const useDeleteFontState = () => useRecoilCallback(deleteFontStateCallback);
export const useHydrateStoreFromTemplate = () => useRecoilCallback(hydrateStoreFromTemplateCallback);
