import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import '@css/Onboarding.css';

import logo from '@assets/img/logo-mono-white.png';

import { useAuth0 } from '@context/Auth0ReactSPA';

import { useAnalytics } from '@components/analytics/Analytics';
import Loader from '@components/loader/Loader';

import { authenticatedUserAtom } from '@store/Auth';

import useDuplicateTemplate from '@hooks/useDuplicateTemplate';
import useUserOnboarding from '@hooks/usePatchUserData';

const jobRoles = ['Developer', 'Product', 'Marketer', 'C-Level', 'Founder', 'Something else'];

const useCases = [
  'Social Media Automation',
  'Classifieds & Listing Video Automation',
  'News Video Automation',
  'Automated Trimming, Concatenation & Stitching',
  // 'E-Commerce Video Automation',
  'Creating Video Editing Capabilities',
  'Other Media Automation Workflow',
];

const industries = [
  'Digital Agency',
  'Real Estate',
  'Automotive',
  'Media & Entertainment',
  'Travel',
  'Creative Agency',
  'Other',
];

const discoveryMethods = [
  'Search Engine (Google, Bing, etc.)',
  'Word of Mouth',
  'YouTube',
  'Twitter',
  'ChatGPT',
  'GitHub',
  'Blog Post',
  'Zapier / Make',
  'Other',
];
const buildTypes = [
  { label: 'With Code', value: 'With Code' },
  { label: 'Without Code', value: 'No-Code' },
];

export default function Onboarding() {
  const { loading, user } = useAuth0();
  const [searchParams] = useSearchParams();
  const history = useNavigate();
  const setAuthUser = useSetRecoilState(authenticatedUserAtom);
  const [agree, setAgree] = useState(false);
  const [role, setRole] = useState('');
  const [buildType, setBuildType] = useState('');
  const [isSuccess, setSuccess] = useState(false);
  const [discoveryMethod, setDiscoveryMethod] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [useCase, setUseCase] = useState('');
  const [industry, setIndustry] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { onboardUser } = useUserOnboarding();
  const { trackEvent, identifyUser } = useAnalytics();
  const { handleDuplicate } = useDuplicateTemplate({
    onSuccess: ({ id }) => history(`/studio/editor/${id}`),
  });

  const handleJobRoleSelection = (e) => {
    setRole(e.target.value);
  };

  const handleIndustrySelection = (e) => {
    setIndustry(e.target.value);
  };

  const handleDiscoveryMethods = (e) => {
    setDiscoveryMethod(e.target.value);
  };

  const handleUseCaseSelection = (e) => {
    setUseCase(e.target.value);
  };

  const submitOnboardingForm = async (event) => {
    event.preventDefault();

    if (!agree) return;

    const data = {
      auth0Id: user.sub.split('|')[1],
      email: user.email,
      firstName,
      lastName,
      company,
      role,
      buildType,
      industry,
      useCase,
      discoveryMethod,
      termsAndConditionsAccepted: agree,
      privacyPolicyAccepted: agree,
    };

    setIsSubmitting(true);

    trackEvent('Submit Onboarding Form', { userId: user.sub.split('|')[1], ...data });
    identifyUser(user.sub.split('|')[1], {
      email: user.email,
      firstName,
      lastName,
      company,
      role,
      buildType,
      industry,
      useCase,
    });

    try {
      await onboardUser(data);
      setAuthUser((prevState) => ({
        ...prevState,
        ...data,
      }));
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
    }
  };

  useEffect(() => {
    if (firstName || lastName) return;

    if (user?.given_name) setFirstName(user.given_name);
    if (user?.family_name) setLastName(user.family_name);
  }, [user]);

  useEffect(() => {
    trackEvent('View Registration');
  }, []);

  useEffect(() => {
    if (!isSuccess) return;

    const redirectUrl = searchParams.get('redirect');

    if (
      typeof redirectUrl !== 'string' ||
      !redirectUrl.trim().length ||
      !redirectUrl.includes('studio/editor/') ||
      !redirectUrl.includes('workflows//') ||
      !redirectUrl.includes('utilities/')
    ) {
      if (useCase === 'Social Media Automation') {
        handleDuplicate('automated-tiktok-video-bedtime-story');
        return;
      } else if (useCase === 'Classifieds & Listing Video Automation') {
        handleDuplicate('real-estate-slideshow-sd-overlays-merge');
        return;
      } else if (useCase === 'News Video Automation') {
        handleDuplicate('breaking-news-summary');
        return;
      } else if (useCase === 'Automated Trimming, Concatenation & Stitching') {
        history('/apis/playground/edit-api');
        return;
      } else if (useCase === 'E-Commerce Video Automation') {
        handleDuplicate('new-arrivals-spotlight');
        return;
      } else if (useCase === 'Creating Video Editing Capabilities') {
        history('/studio/sdk');
        return;
      }
      history('/');
      return;
    }

    window.location.href = redirectUrl;
  }, [history, isSuccess]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container fluid className="vh-100 d-flex align-items-center justify-content-center">
      <Row className="vw-100 vh-100">
        <Col md={3} className="onboarding-side-panel d-md-block d-none">
          {' '}
          <img className="onboarding-logo position-absolute p-5" src={logo} alt="Shotstack Logo" />
          <Row className="d-flex text-center align-items-center justify-content-center vh-100 px-3">
            <h3 className="text-light">Create thousands of unique videos in minutes with Shotstack</h3>
          </Row>
        </Col>
        <Col md={9} className="d-flex align-items-center justify-content-center vh-md-100 my-5">
          {isSubmitting ? (
            <Spinner />
          ) : (
            <Form onSubmit={submitOnboardingForm} className="onboarding-form">
              <Row className="my-2">
                <h2>Welcome to Shotstack</h2>
                <p className="lead">Tell us about yourself, and we&apos;ll personalise your experience.</p>
              </Row>
              <Row className="my-4">
                <Col md={4}>
                  <Form.Group controlId="formBasicFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formBasicLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="my-4">
                <Col md={4}>
                  <Form.Group controlId="formBasicCompany">
                    <Form.Label>Company</Form.Label>
                    <Form.Control type="text" value={company} onChange={(e) => setCompany(e.target.value)} required />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formIndustry">
                    <Form.Label>What industry do you work in?</Form.Label>
                    <Form.Select value={industry} onChange={handleIndustrySelection} required>
                      <option value="">Select ...</option>
                      {industries.map((industry) => (
                        <option key={industry} value={industry}>
                          {industry}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="my-4">
                <Col md={4}>
                  <Form.Group controlId="formBasicBuildType">
                    <Form.Label>How do you want to automate your videos?</Form.Label>
                    {buildTypes.map((type, idx) => (
                      <Form.Check
                        type="radio"
                        required
                        key={idx}
                        label={type.label}
                        value={type.value}
                        checked={buildType === type.value}
                        onChange={(e) => setBuildType(e.target.value)}
                        name="buildType"
                        id={`build-type-${type.value}`}
                      />
                    ))}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formBasicJobRole">
                    <Form.Label>Job Role</Form.Label>
                    <Form.Select value={role} onChange={handleJobRoleSelection} required>
                      <option value="">Select ...</option>
                      {jobRoles.map((role) => (
                        <option key={role} value={role}>
                          {role}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="my-4">
                <Col md={4}>
                  <Form.Group controlId="formBasicUseCase">
                    <Form.Label>What are you looking to build?</Form.Label>
                    <Form.Select value={useCase} onChange={handleUseCaseSelection} required>
                      <option value="">Select ...</option>
                      {useCases.map((useCase) => (
                        <option key={useCase} value={useCase}>
                          {useCase}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formBasicReferral">
                    <Form.Label>How did you hear about us?</Form.Label>
                    <Form.Select value={discoveryMethod} onChange={handleDiscoveryMethods} required>
                      <option value="">Select ...</option>
                      {discoveryMethods.map((method) => (
                        <option key={method} value={method}>
                          {method}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="my-2">
                <Col md={12}>
                  <Form.Group className="d-flex align-items-center" controlId="formBasicCheckbox">
                    <Form.Check
                      className="me-2"
                      type="checkbox"
                      checked={agree}
                      onChange={() => setAgree(!agree)}
                      required
                    />
                    <Form.Check.Label className="mb-0">
                      I agree to the{' '}
                      <a href="https://shotstack.io/terms/" target="_blank" rel="noopener noreferrer">
                        terms
                      </a>{' '}
                      and{' '}
                      <a href="https://shotstack.io/privacy/" target="_blank" rel="noopener noreferrer">
                        privacy policy
                      </a>
                    </Form.Check.Label>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="my-2 justify-content-right">
                <Col md={12}>
                  <Button variant="primary" type="submit">
                    Continue
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
}
