export const calculateDimensions = (params) => {
  const {
    assetHeight,
    assetWidth,
    canvasHeight,
    canvasWidth,
    dimensions = undefined,
    fit = 'crop',
    scale,
    targetAspectRatio,
  } = params;

  if (!assetWidth || !assetHeight) {
    return { aspectRatio: canvasWidth / canvasHeight, width: canvasWidth, height: canvasHeight, scale };
  }

  let aspectRatio = Math.max(canvasWidth / assetWidth, canvasHeight / assetHeight);
  let width;
  let height;

  switch (fit) {
    case 'contain':
      aspectRatio = Math.min(canvasWidth / assetWidth, canvasHeight / assetHeight);
      width = targetAspectRatio ? assetWidth : aspectRatio * assetWidth;
      height = targetAspectRatio ? assetHeight : aspectRatio * assetHeight;
      break;
    case 'cover':
      width = targetAspectRatio ? assetWidth : canvasWidth;
      height = targetAspectRatio ? assetHeight : canvasHeight;
      break;
    case 'none':
      width = dimensions ? dimensions.width : assetWidth;
      height = dimensions ? dimensions.height : assetHeight;
      break;
    default:
      width = targetAspectRatio ? assetWidth : aspectRatio * assetWidth;
      height = targetAspectRatio ? assetHeight : aspectRatio * assetHeight;
  }

  return {
    aspectRatio,
    height: height * scale,
    width: width * scale,
    scale,
  };
};

export const calculatePosition = (params) => {
  const {
    assetWidth,
    assetHeight,
    canvasHeight,
    canvasWidth,
    position = 'center',
    positionX = undefined,
    positionY = undefined,
    scale,
  } = params;

  let x;
  let y;

  if (!assetWidth || !assetHeight) {
    return { x: canvasWidth / 2, y: canvasHeight / 2 };
  }

  if (positionX !== undefined || positionY !== undefined) {
    const x = positionX !== undefined ? canvasWidth / 2 + canvasWidth * positionX : canvasWidth / 2;
    const y = positionY !== undefined ? canvasHeight / 2 - canvasHeight * positionY : canvasHeight / 2;

    return { x, y };
  }

  switch (position) {
    case 'top':
      x = canvasWidth / 2;
      y = assetHeight / 2 - (assetHeight - assetHeight * scale) / 2;
      break;
    case 'topRight':
      x = canvasWidth - assetWidth / 2 + (assetWidth - assetWidth * scale) / 2;
      y = assetHeight / 2 - (assetHeight - assetHeight * scale) / 2;
      break;
    case 'right':
      x = canvasWidth - assetWidth / 2 + (assetWidth - assetWidth * scale) / 2;
      y = canvasHeight / 2;
      break;
    case 'bottomRight':
      x = canvasWidth - assetWidth / 2 + (assetWidth - assetWidth * scale) / 2;
      y = canvasHeight - assetHeight / 2 + (assetHeight - assetHeight * scale) / 2;
      break;
    case 'bottom':
      x = canvasWidth / 2;
      y = canvasHeight - assetHeight / 2 + (assetHeight - assetHeight * scale) / 2;
      break;
    case 'bottomLeft':
      x = assetWidth / 2 - (assetWidth - assetWidth * scale) / 2;
      y = canvasHeight - assetHeight / 2 + (assetHeight - assetHeight * scale) / 2;
      break;
    case 'left':
      x = assetWidth / 2 - (assetWidth - assetWidth * scale) / 2;
      y = canvasHeight / 2;
      break;
    case 'topLeft':
      x = assetWidth / 2 - (assetWidth - assetWidth * scale) / 2;
      y = assetHeight / 2 - (assetHeight - assetHeight * scale) / 2;
      break;
    default:
      x = canvasWidth / 2;
      y = canvasHeight / 2;
  }

  return {
    x,
    y,
  };
};

export const calculateResize = (params) => {
  const { assetHeight, assetWidth, position, mousePosition, handlePosition, xResizeOrigin, yResizeOrigin } = params;

  let xMove = 0;
  let yMove = 0;

  const resizedPosition = { ...position };

  switch (handlePosition) {
    case 'top':
      yMove = mousePosition.y - yResizeOrigin;
      resizedPosition.y = position.y + yMove / 2;
      break;
    case 'right':
      xMove = mousePosition.x - xResizeOrigin;
      resizedPosition.x = position.x + xMove / 2;
      break;
    case 'bottom':
      yMove = yResizeOrigin - mousePosition.y;
      resizedPosition.y = position.y - yMove / 2;
      break;
    case 'left':
      xMove = xResizeOrigin - mousePosition.x;
      resizedPosition.x = position.x - xMove / 2;
      break;
    default:
      xMove = mousePosition.x - xResizeOrigin;
      yMove = mousePosition.y - yResizeOrigin;
  }

  return {
    resizedPosition,
    resizedWidth: Math.round(assetWidth + xMove),
    resizedHeight: Math.round(assetHeight - yMove),
  };
};

export const calculateScale = (params) => {
  const {
    assetHeight,
    assetWidth,
    mousePosition,
    position,
    scaleOrigin,
    xScaleOrigin,
    yScaleOrigin,
    fit,
    canvasHeight,
    canvasWidth,
    targetAspectRatio,
  } = params;

  const calculateMove = () => {
    switch (position) {
      case 'topLeft':
        return { x: xScaleOrigin - mousePosition.x, y: yScaleOrigin - mousePosition.y };
      case 'bottomLeft':
        return { x: xScaleOrigin - mousePosition.x, y: mousePosition.y - yScaleOrigin };
      case 'topRight':
        return { x: mousePosition.x - xScaleOrigin, y: yScaleOrigin - mousePosition.y };
      case 'bottomRight':
        return { x: mousePosition.x - xScaleOrigin, y: mousePosition.y - yScaleOrigin };
      default:
        return { x: mousePosition.x - xScaleOrigin, y: mousePosition.y - yScaleOrigin };
    }
  };

  const { x: xMove, y: yMove } = calculateMove();

  const moveValue = (xMove * 1.66) ** 2 + (yMove * 1.66) ** 2;
  let moveScale = Math.sqrt(moveValue) / Math.max(assetWidth, assetHeight);
  moveScale *= xMove + yMove < 0 ? -1 : 1;

  const scale = Math.max(scaleOrigin + moveScale, 0.01);

  const calculateDimensions = () => {
    if (targetAspectRatio) {
      const [targetWidth, targetHeight] = targetAspectRatio.split(':').map(Number);
      const targetAspectRatioValue = targetWidth / targetHeight;
      const sourceAspectRatio = assetWidth / assetHeight;

      let width, height;

      if (sourceAspectRatio > targetAspectRatioValue) {
        // Fit to height
        height = assetHeight * scale;
        width = height * targetAspectRatioValue;
      } else {
        // Fit to width
        width = assetWidth * scale;
        height = width / targetAspectRatioValue;
      }

      return { width, height };
    } else if (fit === 'cover') {
      const aspectRatio = Math.max(canvasWidth / assetWidth, canvasHeight / assetHeight);
      return {
        width: (canvasWidth / aspectRatio) * scale,
        height: (canvasHeight / aspectRatio) * scale,
      };
    } else {
      return {
        width: assetWidth * scale,
        height: assetHeight * scale,
      };
    }
  };

  const { width: resizedWidth, height: resizedHeight } = calculateDimensions();

  return {
    resizedScale: scale,
    resizedWidth: Math.round(resizedWidth),
    resizedHeight: Math.round(resizedHeight),
  };
};

export const calculateAngle = (params) => {
  const { mousePosition, angleOrigin, xScaleOrigin, yScaleOrigin, position } = params;

  const shapeCenter = {
    x: position.x,
    y: position.y,
  };

  const initialAngleRad = Math.atan2(yScaleOrigin - shapeCenter.y, xScaleOrigin - shapeCenter.x);
  const currentAngleRad = Math.atan2(mousePosition.y - shapeCenter.y, mousePosition.x - shapeCenter.x);

  let angleDifferenceRad = currentAngleRad - initialAngleRad;
  let angleDifferenceDeg = angleDifferenceRad * (180 / Math.PI);

  let angle = angleOrigin + angleDifferenceDeg;

  angle = (angle + 360) % 360;
  angle = parseFloat(angle.toFixed(2));

  return angle;
};
