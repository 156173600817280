import { atom, selector } from 'recoil';

export const sdkEnabledAtom = atom({
  key: 'sdkEnabledAtom',
  default: false,
});

export const sdkLoadingAtom = atom({
  key: 'sdkLoadingAtom',
  default: true,
});

export const sdkCustomerAtom = atom({
  key: 'sdkCustomerAtom',
  default: false,
});

export const sdkOriginAtom = atom({
  key: 'sdkOriginAtom',
  default: null,
});

export const sdkOptionsAtom = atom({
  key: 'sdkOptionsAtom',
  default: {
    isInteractive: true,
    isTimeline: true,
    isSidepanel: true,
    isControls: true,
    isSettings: true,
    isLogoUrl: false,
    primaryColor: '#25d3d0',
    secondaryColor: '#b3a0f8',
  },
});

export const sdkOptionsSelector = selector({
  key: 'sdkOptionsSelector',
  get: ({ get }) => {
    return {
      isSdkEnabled: get(sdkEnabledAtom),
      isSdkCustomer: get(sdkCustomerAtom),
      origin: get(sdkOriginAtom),
      ...get(sdkOptionsAtom),
    };
  },
});
