import cn from 'classnames';
import React, { useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { IconAutomate, IconChevronRight, IconConnect, IconDesign } from '@assets/icons';

import ToolbarLeft from '@feature/studio/toolbar/ToolbarLeft';
import ToolbarRight from '@feature/studio/toolbar/ToolbarRight';

import { workspaceTabAtom } from '@store/Edit';

const topbarItems = [
  {
    id: 'design',
    label: 'Design',
    icon: IconDesign,
    tooltip: 'Create your template',
    path: '',
  },
  {
    id: 'connect',
    label: 'Connect',
    icon: IconConnect,
    tooltip: 'Connect to storage or distribute',
    path: '/connect',
    iconSize: 20,
  },
  {
    id: 'automate',
    label: 'Automate',
    icon: IconAutomate,
    tooltip: 'Automate your workflow',
    path: '/automate',
    iconSize: 16,
  },
];

function WorkspaceTopbar() {
  const { id } = useParams();
  const location = useLocation();
  const [workspaceTab, setWorkspaceTab] = useRecoilState(workspaceTabAtom);

  const determineActiveTab = (pathname) => {
    let tab = 'design';
    if (pathname.includes('connect')) tab = 'connect';
    if (pathname.includes('automate')) tab = 'automate';
    return tab;
  };

  useEffect(() => {
    setWorkspaceTab(determineActiveTab(location.pathname));
  }, [location.pathname]);

  return (
    <div className="workspace__topbar">
      <ToolbarLeft />

      <div className="d-flex items-center justify-content-center flex-grow-1 gap-3">
        {topbarItems.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              <OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltip-${item.id}`}>{item.tooltip}</Tooltip>}>
                <Link
                  to={`/studio/editor/${id}${item.path}`}
                  className={cn('topbar__link', item.id, { active: workspaceTab === item.id })}
                >
                  <div className={cn('d-flex items-center gap-2')}>
                    <item.icon size={item.iconSize} />
                    {item.label}
                  </div>
                </Link>
              </OverlayTrigger>
              {index < topbarItems.length - 1 && <IconChevronRight size={18} className="mx-2" />}
            </React.Fragment>
          );
        })}
      </div>

      <ToolbarRight />
    </div>
  );
}

export default WorkspaceTopbar;
